import {map} from 'lodash'

export const deleteMenuItem = ({ menuItems, setMenuItems, item }) => {
         const newMenu = menuItems.filter((i) => i.id !== item.id);

         setMenuItems(newMenu);
       };

export const parseData = ({data: {menu: {items}}}) => {
  let id = 0
  const menuItems = map(items, (item) => {
      if (item === null) {
        item = {
          name: 'separator',
          id: id.toString()
        }
      } else {
        item = {
          ...item,
          id: id.toString()
        }
      }

      id += 1
      return item
    })

  return menuItems
}

export const onDragEnd = ({ result, menu, setMenuItems }) => {
         const { destination, source, draggableId } = result;

         if (!destination) {
           return;
         }

         if (
           destination.droppableId === source.droppableId &&
           destination.index === source.index
         ) {
           return;
         }

         const newMenu = Array.from(menu);
         const item = newMenu.splice(source.index, 1)[0];
         item.id = draggableId;
         newMenu.splice(destination.index, 0, item);

         setMenuItems(newMenu);
       };

export const findConfig = ({appsConfig, app}) => {
  return appsConfig.find(c => {
    if (app.type === "app") {
      return app.app_name === c.id;
    } else {
      return app.type === c.id;
    }
  });
};
