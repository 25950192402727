import React, { useState, useEffect } from "react";
import {AppType, MenuItemType, SpaceType} from "./initial_data";
import Menu from "./menu";
import EditItem from "./new/create_modal";
import { onDragEnd } from "./common_methods";
import styled from "styled-components";
import "./fontello-b3bffaa0/css/fontello.css";
import { DragDropContext } from "react-beautiful-dnd";
import { MenuItem } from "./menu/menu_item";

const Container = styled.div`
  display: flex;
  margin: 0 auto;
  width: calc(900px + 1rem);
`;

const MenuContainer = styled.div`
  flex: 300px;
  max-width: 300px;
`
const EditContainer = styled.div`
  flex: 600px;
  max-width: 600px;
  margin-left: 1rem;
`


const parseConfig = ({
  items,
  appsConfig,
  systemConfig,
}: {
  items: MenuItemType[];
  appsConfig: AppType[];
  systemConfig: AppType[];
}): MenuItem[] => {
  const MenuItems = items.map((e) => new MenuItem({ item: e, appsConfig: [...appsConfig, ...systemConfig] }));

  return MenuItems;
};

const NewButton = styled.header`
  text-align: center;
  border-top: 1px solid #666666;
  border-right: 1px solid #666666;
  border-left: 1px solid #666666;
  width: 182px;
  background-color: green;
  color: white;
  padding: 3px;
  cursor: pointer;
`;

const ExportButton = styled.a`
  text-align: center;
  border-right: 1px solid #666666;
  border-left: 1px solid #666666;
  width: 182px;
  background-color: #48C774;
  color: white;
  padding: 3px;
  cursor: pointer;
`;

interface AppProps{
  menuItems: MenuItem[],
  apps: AppType[],
  spaces: SpaceType[],
  systemPages: AppType[],
  mode: string
};

const App: React.FC<AppProps> = ({ menuItems: defaultMenuItems, apps, spaces, systemPages, mode }) => {
  const [menuItems, setMenuItems] = useState(defaultMenuItems)

  const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem | null>(null)

  const exportMenu = buildExportMenu({ menuItems, apps, systemPages, spaces });

  useEffect(() => {
    const callback = (e: any) => {
      const eventName = JSON.parse(e.data)[0]
      if (eventName === 'export_menu_config') {
        window.parent.postMessage(JSON.stringify(['menu_config_exported', exportMenu()]), '*');
      }
    }

    window.addEventListener('message', callback, false)
    return () => {
      window.removeEventListener('message', callback, false)
    }
  }, [exportMenu])

  const addMenuItem = () => {
    const item = {
        id: Date.now().toString(),
        type: "new"
      },
      newItem = new MenuItem({ item, appsConfig: [...apps, ...systemPages] });
    setMenuItems([newItem, ...menuItems]);
    setSelectedMenuItem(newItem)
  }

  const onCancel = () => {
    setSelectedMenuItem(null)
    setMenuItems(menuItems.filter(({type}) => type !== 'new'))
  };

  return (
    <DragDropContext
      onDragEnd={(result) =>
        onDragEnd({ result, menu: menuItems, setMenuItems })
      }
    >
      <Container>
        <MenuContainer>
          <div>
            <NewButton onClick={addMenuItem} className="is-link">
              Add Menu Item +
            </NewButton>
          </div>
          <Menu
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
            setMenuItems={setMenuItems}
            editMenuItem={setSelectedMenuItem}
          />

          {mode === 'local' && (
            <ExportButton
              href={URL.createObjectURL(new Blob([JSON.stringify(exportMenu())], { type: "application/json" }))}
              download={"config"}
            >
              Export
            </ExportButton >
          )}
        </MenuContainer>
        <EditContainer>
          {selectedMenuItem && (
            <EditItem
              key={selectedMenuItem.id}
              apps={apps}
              spaces={spaces}
              menuItem={selectedMenuItem}
              onSave={buildOnSave(selectedMenuItem)}
              onCancel={onCancel}
            />
          )}
          {
            !selectedMenuItem && (
              <div style={{padding: '2rem', textAlign: 'center'}}>
                Select an item on the side
              </div>
            )
          }

        </EditContainer>
      </Container>
    </DragDropContext>
  );
}

const LoadedApp = ({json, mode}: {json: any, mode: string}) => {
  const [rawJson, setRawJson] = useState<any | undefined>(json)

  useEffect(() => {
    setRawJson(json)
  }, [json])

  if (!rawJson) {
    return (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 200 }}
      >
        <input
          type="file"
          name="file"
          style={{ display: "none" }}
          hidden
          id="file"
          onChange={(e) => {
            const file =
                e.target && e.target.files && (e.target.files[0] as any | null),
              fileReader = new FileReader();
            fileReader.readAsText(file, "UTF-8");
            fileReader.onload = function (evt) {
              if (evt.target && evt.target.result) {
                const config = JSON.parse(evt.target.result as string);
                setRawJson(config);
              }
            };
          }}
        />
        <label
          htmlFor="file"
          style={{ marginTop: 5 }}
          className={"button is-primary"}
        >
          Upload Menu Config
        </label>
      </div>
    );
  } else {
    return (
      <App
        menuItems={parseConfig({
          items: rawJson.menu.items,
          appsConfig: rawJson.apps.map(mapAppsForApp),
          systemConfig: rawJson.system.map(mapAppsForApp)
        })}
        mode={mode}
        apps={rawJson.apps.map(mapAppsForApp)}
        systemPages={rawJson.system.map(mapAppsForApp)}
        spaces={rawJson.groups.map(mapSpacesForApp)}
      />
    );
  }
};

const FinishedApp: React.FC = () => {

  const[menuJson, setMenuJson] = useState(null)
  const[mode, setMode] = useState('local')

  useEffect(() => {
    const callback = (e: any) => {
      const eventData = JSON.parse(e.data),
        eventName = eventData[0],
        data = eventData[1];

      if (eventName === 'load_menu_config') {
        setMenuJson(JSON.parse(data))
        setMode('gov')
        window.parent.postMessage(JSON.stringify(['menu_config_loaded', {}]), '*');
      }
    }

    window.addEventListener('message', callback, false)
    return () => {
      window.removeEventListener('message', callback, false)
    }
  }, [])

  return (
      <AppErrorHandler>
        <LoadedApp json={menuJson} mode={mode} />
      </AppErrorHandler>
  );
}

export default FinishedApp


const mapAppsForApp = ({
  id,
  human_name,
  icon_name: icon,
  url,
}: {
  id: string;
  human_name: string;
  icon_name: string;
  url: string;
}) => ({ id, human_name, icon, url } as AppType);

const mapSpacesForApp = ({
  id,
  name
}: {
  id: string;
  name: string
}) => ({id, name} as SpaceType)

const buildExportMenu = ({
  menuItems,
  apps,
  systemPages,
  spaces
}: {
  menuItems: MenuItem[];
  apps: AppType[];
  systemPages: AppType[];
  spaces: SpaceType[];
}) => {
  return () => {
    return ({
      menu: {
        items: menuItems.map((m) => m.asJson())
      },
      apps: apps.map(({id, url, icon: icon_name, human_name}) => ({id, url, icon_name, human_name})),
      system: systemPages.map(({id, url, icon: icon_name, human_name}) => ({id, url, icon_name, human_name})),
      groups: spaces
    })
  }
};

function buildOnSave(selectedMenuItem: MenuItem) {
  return ({ type, app, uuid, url, humanName, icon, onlyShowToSpaceUuids }: {
    type: string;
    app: string;
    uuid: string;
    url: string;
    humanName: string;
    icon: string;
    onlyShowToSpaceUuids: string[];
  }) => {
    selectedMenuItem.type = type;
    selectedMenuItem.human_name = "";
    selectedMenuItem.icon = "";
    selectedMenuItem.onlyShowToSpaceUuids = !onlyShowToSpaceUuids || onlyShowToSpaceUuids.length === 0 ? null : onlyShowToSpaceUuids;

    if (selectedMenuItem.type === "app") {
      selectedMenuItem.options.app_name = app;
    }

    if (selectedMenuItem.type === "resource") {
      selectedMenuItem.uuid = uuid;
      selectedMenuItem.human_name = humanName;
      selectedMenuItem.icon = icon;
    }
    if (selectedMenuItem.type === "external_link") {
      selectedMenuItem.url = url;
      selectedMenuItem.human_name = humanName;
      selectedMenuItem.icon = icon;
    }
  };
}

class AppErrorHandler extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  state = {
    hasError: false
  }


  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({hasError: true})
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
