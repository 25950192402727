
import React, { useState } from "react";
import styled from "styled-components";
import IconsModal from './icons-modal'

const IconContainer = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-between;
  margin-right: 5px;
  align-items: center;
  margin-top: 10px;
`;

interface Props {
  setAction: (str: string) => void;
  title: string;
  value: string | undefined;
}

const Icon = ({title, setAction, value}: Props) => {
  const [iconModalOpen, setIconModalOpen] = useState<boolean>(false);
  
  return (
    <IconContainer>
      {title}
      <i
        onClick={() => setIconModalOpen(true)}
        className={`icon-${value}`}
        style={{ fontSize: 20 }}
      />
      <IconsModal
        iconModalOpen={iconModalOpen}
        setIcon={setAction}
        setIconModalOpen={setIconModalOpen}
      />
    </IconContainer>
  );
}

export default Icon
