import React from "react";
import styled from "styled-components";

const InputContainer = styled.div`
  display: flex;
  padding: 5px;
  height: 30px;
  align-items: center;
  margin-top: 20px;
`;

const RequiredContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  color: #F14668;
  margin-right: 10px;
  margin-top 6px;
  font-size: 12px;
`;

interface Props {
  setAction: (str: string) => void;
  title: string;
  value: string | undefined;
  placeholder?: string;
  required?: boolean
}

const Input = ({ setAction, title, value, placeholder, required }: Props) => {
  return (
    <div>
      <InputContainer>
        <span style={{ width: 120 }}>{title}</span>
        <input
          type="text"
          placeholder={placeholder}
          style={{ marginLeft: 20 }}
          className="input"
          value={value}
          onChange={(e) => setAction(e.target.value)}
        ></input>
      </InputContainer>
      {required && <RequiredContainer style={{}}>* Required</RequiredContainer>}
    </div>
  );
};

export default Input
