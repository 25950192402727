import React from "react";
import MenuItem from "../menu/menu_item";
import styled from "styled-components";
import { MenuItemOption } from "./menu.item.options";

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

interface PreviewProps {
  menuItem: MenuItem;
}

const Preview: React.FC<PreviewProps> = ({
  menuItem: {type, icon, display_name}
}) => {
  return (
    <div>
      <PreviewContainer>
        {type === "seperator" && (
          <div className="is-divider">
            <hr style={{ margin: ".5rem", width: 200, marginTop: 20 }} />
          </div>
        )}

        {type !== "seperator" && (
          <>
            <i className={`icon-${icon}`} style={{ fontSize: 20 }} />
            {display_name}
          </>
        )}
      </PreviewContainer>
    </div>
  );
};

export default Preview;
