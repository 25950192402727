import React from "react";
import { SpaceType } from "../../initial_data";

interface Props {
  spaces: SpaceType[],
  onlyShowToSpaceUuids: string[],
  setOnlyShowToSpaceUuids: (arg1: string[]) => void
}

const SelectSpaceVisibility: React.FC<Props> = ({spaces, onlyShowToSpaceUuids, setOnlyShowToSpaceUuids}) => {

  const toggleShowSpaceUuid = (spaceUuid: string) => {
    if (onlyShowToSpaceUuids.includes(spaceUuid)) {
      setOnlyShowToSpaceUuids(onlyShowToSpaceUuids.filter((e) => e !== spaceUuid))
    } else {
      setOnlyShowToSpaceUuids([...onlyShowToSpaceUuids, spaceUuid])
    }
  }

  return (
    <div>
      <details>
        <summary>Group visibility</summary>
        <ul>
          {spaces.map(({id, name}) => (
            <li key={id}>
              <label>
                <input type="checkbox" checked={onlyShowToSpaceUuids.includes(id)} onChange={() => toggleShowSpaceUuid(id)} />
                {name} ({id})
              </label>
            </li>
          ))}
        </ul>
      </details>
    </div>
  )
}

export default SelectSpaceVisibility
