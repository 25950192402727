import React from "react";

const Seperator = () => {
  return (
    <div className="is-divider">
      <hr style={{ margin: ".5rem" }} />
    </div>
  );
};

export default Seperator;
