import React from "react";
import Select from "react-select";
import { MenuItemOption } from "./menu.item.options";

interface Props {
  options: MenuItemOption[]
  selectedOption: MenuItemOption | null,
  setSelectedOption: (obj: any) => void,
  callback?: (obj: any) => void,
}

const SelectApp: React.FC<Props> = ({options, selectedOption, setSelectedOption}) => {
  return (
    <Select
      style={{width: 174}}
      value={selectedOption}
      onChange={setSelectedOption}
      options={options}
    />
  );
}

export default SelectApp
