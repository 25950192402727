import React from "react";
import Icon from "../../common/icon";
import Input from "../../common/input";

interface ResourceProps {
  setUuid: (str: string) => void;
  setHumanName: (str: string) => void;
  setIcon: (str: string) => void;
  uuid: string;
  humanName: string;
  icon: string;
}

const Resource: React.FC<ResourceProps> = ({
  setUuid,
  uuid,
  setHumanName,
  humanName,
  setIcon,
  icon,
}) => {
  return (
    <div>
      <Input setAction={setUuid} value={uuid} title="UUID" required={true} />
      <Input setAction={setHumanName} value={humanName} title="Name" />
      <Icon setAction={setIcon} value={icon} title="Icon" />
    </div>
  );
};

export default Resource;
