export interface MenuItemType {
  type: string,
  app_name?: string,
  uuid?: string,
  url?: string,
  human_name?: string,
  icon?: string,
  id: string
}

export interface AppType {
  id: string;
  icon: string;
  url: string;
  human_name: string;
}

export interface SpaceType {
  id: string;
  name: string;
}

export const hardcodedMenuItems = [
         {
           id: "home",
           icon: "list",
           human_name: "Newsfeed",
           url: "/home"
         },
         {
           id: "help",
           icon: "help",
           human_name: "Help",
           url: "/help"
         },
         {
           id: "admin",
           icon: "gauge",
           human_name: "Admin",
           url: "ibfr"
         },
         {
           id: "resource",
           icon: "gauge",
           human_name: "Resource",
           url: "ibfr"
         },
         {
           id: "external_link",
           icon: "gauge",
           human_name: "Resource",
           url: "ibfr"
         },
         {
           id: "seperator",
           icon: "none",
           human_name: "Seperator",
           url: "ibfr"
         }
       ] as AppType[];

export const Icons = [
  "star-half",
  "star-half-alt",
  "ok-circled",
  "ok-circled2",
  "ok-squared",
  "unlink",
  "link-ext",
  "link-ext-alt",
  "pin",
  "eye-1",
  "eye-off",
  "tag-1",
  "tags",
  "attention-alt",
  "doc-1",
  "docs-1",
  "folder-open",
  "cog-alt",
  "wrench",
  "basket-1",
  "mic-1",
  "mute-1",
  "volume-off",
  "volume-down",
  "volume-up",
  "headphones",
  "clock-1",
  "resize-vertical",
  "resize-horizontal",
  "move",
  "zoom-in",
  "zoom-out",
  "down-big",
  "left-big",
  "right-big",
  "up-big",
  "right-hand",
  "left-hand",
  "up-hand",
  "down-hand",
  "expand",
  "collapse",
  "expand-right",
  "collapse-left",
  "eject",
  "target-1",
  "signal-1",
  "award",
  "desktop",
  "laptop",
  "tablet",
  "sun",
  "umbrella",
  "fighter-jet",
  "font",
  "bold",
  "italic",
  "header",
  "text-height",
  "text-width",
  "align-left",
  "align-center",
  "align-right",
  "align-justify",
  "list-1",
  "indent-left",
  "indent-right",
  "list-bullet",
  "list-numbered",
  "strike",
  "underline",
  "superscript",
  "subscript",
  "table",
  "columns",
  "crop",
  "scissors",
  "paste",
  "ellipsis-vert",
  "off",
  "road",
  "qrcode",
  "barcode",
  "book-1",
  "ajust",
  "check-empty",
  "circle",
  "circle-empty",
  "dot-circled",
  "asterisk",
  "gift",
  "fire",
  "key-1",
  "rocket-1",
  "bug",
  "certificate",
  "tasks",
  "filter",
  "beaker",
  "magic",
  "truck",
  "money",
  "euro",
  "pound",
  "dollar",
  "rupee",
  "yen",
  "rouble",
  "try",
  "won",
  "bitcoin",
  "sort",
  "sort-down",
  "sort-up",
  "sort-alt-up",
  "sort-alt-down",
  "sort-name-up",
  "sort-name-down",
  "sort-number-up",
  "sort-number-down",
  "hammer",
  "gauge-1",
  "sitemap",
  "spinner",
  "coffee",
  "food",
  "beer",
  "user-md",
  "stethoscope",
  "ambulance",
  "medkit",
  "h-sigh",
  "hospital",
  "building",
  "smile",
  "frown",
  "meh",
  "anchor",
  "terminal",
  "eraser",
  "puzzle",
  "shield",
  "extinguisher",
  "bullseye",
  "wheelchair",
  "android",
  "apple",
  "linux",
  "pagelines",
  "stackexchange",
  "youtube-play",
  "blank",
  "note",
  "note-beamed",
  "music",
  "search",
  "flashlight",
  "mail",
  "heart",
  "heart-empty",
  "star",
  "star-empty",
  "user",
  "users",
  "user-add",
  "video",
  "picture",
  "camera",
  "layout",
  "menu",
  "check",
  "cancel",
  "cancel-circled",
  "cancel-squared",
  "plus",
  "plus-circled",
  "plus-squared",
  "minus",
  "minus-circled",
  "minus-squared",
  "help",
  "help-circled",
  "info",
  "info-circled",
  "back",
  "home",
  "link",
  "attach",
  "lock",
  "lock-open",
  "eye",
  "tag",
  "bookmark",
  "bookmarks",
  "flag",
  "thumbs-up",
  "thumbs-down",
  "download",
  "upload",
  "upload-cloud",
  "reply",
  "reply-all",
  "forward",
  "quote",
  "code",
  "export",
  "pencil",
  "feather",
  "print",
  "retweet",
  "keyboard",
  "comment",
  "chat",
  "bell",
  "attention",
  "alert",
  "vcard",
  "address",
  "location",
  "map",
  "direction",
  "compass",
  "cup",
  "trash",
  "doc",
  "docs",
  "doc-landscape",
  "doc-text",
  "doc-text-inv",
  "newspaper",
  "book-open",
  "book",
  "folder",
  "archive",
  "box",
  "rss",
  "phone",
  "cog",
  "tools",
  "share",
  "bag",
  "calendar",
  "login",
  "logout",
  "mic",
  "mute",
  "sound",
  "volume",
  "clock",
  "hourglass",
  "lamp",
  "adjust",
  "block",
  "resize-full",
  "resize-small",
  "popup",
  "publish",
  "window",
  "arrow-combo",
  "down-circled",
  "left-circled",
  "right-circled",
  "up-circled",
  "down-open",
  "left-open",
  "right-open",
  "up-open",
  "down-open-mini",
  "left-open-mini",
  "right-open-mini",
  "up-open-mini",
  "down-open-big",
  "left-open-big",
  "right-open-big",
  "up-open-big",
  "down",
  "left",
  "right",
  "up",
  "down-dir",
  "left-dir",
  "right-dir",
  "up-dir",
  "down-bold",
  "left-bold",
  "right-bold",
  "up-bold",
  "down-thin",
  "left-thin",
  "right-thin",
  "up-thin",
  "ccw",
  "cw",
  "arrows-ccw",
  "level-down",
  "level-up",
  "shuffle",
  "loop",
  "switch",
  "play",
  "stop",
  "pause",
  "record",
  "to-end",
  "to-start",
  "fast-forward",
  "fast-backward",
  "progress-0",
  "progress",
  "progress-2",
  "progress-3",
  "target",
  "palette",
  "list",
  "list-add",
  "signal",
  "trophy",
  "mobile",
  "network",
  "cd",
  "inbox",
  "install",
  "globe",
  "paper-plane",
  "lifebuoy",
  "briefcase",
  "suitcase",
  "dot",
  "dot-2",
  "dot-3",
  "brush",
  "infinity",
  "erase",
  "chart-pie",
  "chart-line",
  "chart-bar",
  "chart-area",
  "tape",
  "graduation-cap",
  "language",
  "ticket",
  "air",
  "credit-card",
  "clipboard",
  "megaphone",
  "database",
  "key",
  "flow-cascade",
  "flow-branch",
  "flow-tree",
  "flow-line",
  "flow-parallel",
  "rocket",
  "gauge",
  "traffic-cone",
  "cc",
  "cc-by",
  "cc-nc",
  "cc-nc-eu",
  "cc-nc-jp",
  "cc-sa",
  "cc-nd",
  "cc-pd",
  "cc-zero",
  "cc-share",
  "cc-remix",
  "sweden",
  "db-shape",
  "move-1",
  "at",
  "garden",
  "industrial-building",
  "glass",
  "squiggle"
];
