import { MenuItemType } from "../initial_data";
interface AppConfigProps {
  id: string;
  icon?: string;
  url?: string;
  human_name?: string;
}

interface MenuItemProps {
  item: MenuItemType;
  appsConfig: AppConfigProps[];
}

class MenuItem {
  id: string;
  type: string;
  options: any;
  appsConfig: AppConfigProps[];

  constructor(props: MenuItemProps) {
    const {
      item: { id, type, ...args },
      appsConfig,
    } = props;

    this.id = id;
    this.type = type;
    this.options = args;
    this.appsConfig = appsConfig;
  }

  get onlyShowToSpaceUuids(): string[] | null {
    const spaceAccessRules = this.options["space_access_rules"] || {};

    return spaceAccessRules["only_show_to_space_uuids"];
  }

  set onlyShowToSpaceUuids(uuids: string[] | null) {
    if (!this.options['space_access_rules']) {
      this.options['space_access_rules'] = {}
    }

    this.options['space_access_rules']["only_show_to_space_uuids"] = uuids;
  }

  get config(): AppConfigProps | undefined {
    return this.appsConfig.find(({ id }) => {
      if (this.type === "app") {
        return id === this.options.app_name;
      } else if (!["resource", "external_link"].includes(this.type)) {
        return id === this.type;
      } else {
        return false;
      }
    });
  }

  get allowIconChange(): boolean {
    return ["resource", "external_link"].includes(this.type);
  }

  get display_name(): string {
    if (this.systemApp) {
      return (this.config && this.config.human_name) || '(System app)'
    }

    if (this.type === 'app') {
      return (this.config && this.config.human_name) || '(App name)'
    }

    if (this.type === 'resource') {
      return this.options.human_name || "(Resource name)";
    }

    if (this.type === "external_link") {
      return this.options.human_name || this.options.url || '(External Link)'
    }

    return this.human_name
  }

  set human_name(value: string) {
    this.options.human_name = value;
  }

  get human_name(): string {
    if (this.options.human_name) {
      return this.options.human_name;
    }

    if (this.config && this.config.human_name) {
      return this.config.human_name;
    }

    return ''
  }

  get appName(): string | undefined {
    return this.options.app_name;
  }

  set icon(value: string) {
    this.options.icon = value;
  }

  set uuid(value: string | undefined) {
    this.options.uuid = value;
  }

  get uuid(): string | undefined {
    if (this.options.uuid) {
      return this.options.uuid;
    } else {
      return undefined;
    }
  }

  set url(value: string | undefined) {
    this.options.url = value;
  }

  get url(): string | undefined {
    if (this.options.url) {
      return this.options.url;
    } else {
      return undefined;
    }
  }

  get icon(): string {
    if (this.systemApp) {
      return (this.config && this.config.icon) || 'gauge'
    }

    if (this.type === 'app') {
      return (this.config && this.config.icon) || 'gauge'
    }

    if (this.options.icon) {
      return this.options.icon;
    }

    return "gauge";
  }

  get systemApp(): boolean {
    return ["admin", "home", "help"].includes(this.type)
  }

  asJson(): object {
    switch (this.type) {
      case "app":
        return {
          id: this.id,
          type: "app",
          app_name: this.appName,
          space_access_rules: this.options['space_access_rules']
        };
      case "resource":
        return {
          id: this.id,
          type: "resource",
          uuid: this.uuid,
          icon: this.icon,
          human_name: this.human_name,
          space_access_rules: this.options['space_access_rules']
        };
      case "external_link":
        return {
          id: this.id,
          type: "external_link",
          url: this.url,
          icon: this.icon,
          human_name: this.human_name,
          space_access_rules: this.options['space_access_rules']
        };
      default:
        return {
          id: this.id,
          type: this.type,
          space_access_rules: this.options['space_access_rules']
        };
    }
  }
}

export { MenuItem }
export default MenuItem;
