import React from 'react'
import Icon from "../../common/icon";
import Input from "../../common/input";

interface UrlProps {
  setUrl: (str: string) => void;
  setHumanName: (str: string) => void,
  setIcon: (str: string) => void,
  url: string,
  humanName: string,
  icon: string
};

const Url: React.FC<UrlProps> = ({setUrl, url, setHumanName, humanName, setIcon, icon}) => {
  return (
    <div>
      <Input setAction={setUrl} value={url} title="Url" required={true} />
      <Input setAction={setHumanName} value={humanName} title="Name" />
      <Icon setAction={setIcon} value={icon} title="Icon" />
    </div>
  );
}

export default Url