import React from "react";
import SelectApp from '../select.app'
import { MenuItemOption } from "../menu.item.options";

interface ResourceProps {
  setApp: (str: string) => void,
  appOptions: MenuItemOption[],
  selectedAppName: string
}

const Resource: React.FC<ResourceProps> = ({
  setApp,
  appOptions,
  selectedAppName,
}) => {
  return (
    <div style={{ marginTop: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ marginRight: 10 }}>App </span>
        <span style={{ width: 174, marginRight: 5 }}>
          <SelectApp
            setSelectedOption={(o) => {
              setApp(o.value);
            }}
            selectedOption={appOptions.find(
              ({ value }) => value === selectedAppName
            ) as MenuItemOption}
            options={appOptions}
          />
        </span>
      </div>
    </div>
  );
};

export default Resource;
