import React, { useState } from "react";
import styled from "styled-components";
import { MenuItem } from "../menu/menu_item";
import SelectType from './select.app'
import { MenuItemOptions } from "./menu.item.options";
import Form from './forms/form'
import SelectSpaceVisibility from './forms/select_space_visibility'
import Preview from "./preview";
import { AppType, SpaceType } from "../initial_data";


const Container = styled.div`
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

interface Props {
  menuItem: MenuItem,
  apps: AppType[],
  spaces: SpaceType[],
  onSave: (newOptions: any) => void,
  onCancel: () => void
}

const CreateItem: React.FC<Props> = ({
  menuItem,
  apps,
  spaces,
  onSave,
  onCancel
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(menuItem.type || 'home')

  const [humanName, setHumanName] = useState<string>(menuItem.human_name),
    [icon, setIcon] = useState<string>(menuItem.icon),
    [url, setUrl] = useState<string>(menuItem.url || ""),
    [uuid, setUuid] = useState<string>(menuItem.uuid || ""),
    [app, setApp] = useState<string>(menuItem.appName || ""),
    [onlyShowToSpaceUuids, setOnlyShowToSpaceUuids] = useState<string[]>(menuItem.onlyShowToSpaceUuids || []);

  const newMenuItem = new MenuItem({
    item: {
      id: menuItem.id,
      type: selectedOption,
      human_name: humanName,
      icon: icon,
      url: url,
      uuid: uuid,
      app_name: app
    },
    appsConfig: menuItem.appsConfig
  })

  const _onSave = () => {
    onSave({
      type: selectedOption,
      app,
      uuid,
      url,
      icon,
      humanName,
      onlyShowToSpaceUuids
    })
    onCancel()
  };

  const isDisabled = () => {
    if (selectedOption === "resource") {
      return !uuid;
    } else if (selectedOption === "external_link") {
      return !url;
    } else if (selectedOption === "app") {
      return !app;
    }

    return false;
  };

  return (
    <Container>
      <Header>
        <div>
          <button className="button is-light" onClick={onCancel}>
            Cancel
          </button>
        </div>
        <div style={{flexGrow: 1, textAlign: 'center'}}>
          Menu Item
        </div>
        <div>
          <button
            className="button is-link"
            onClick={_onSave}
            disabled={isDisabled()}
            style={{ marginRight: 10 }}
          >
            Save
          </button>
        </div>
      </Header>
      <hr />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span style={{ marginRight: 10 }}>Type </span>
        <span style={{ width: 174, marginRight: 5 }}>
          <SelectType
            setSelectedOption={({value}) => setSelectedOption(value)}
            selectedOption={MenuItemOptions.find(({value}) => value === selectedOption) || null}
            options={MenuItemOptions}
          />
        </span>
      </div>
      <Form
        selectedOption={selectedOption}
        humanName={humanName}
        setHumanName={setHumanName}
        icon={icon}
        setIcon={setIcon}
        url={url}
        setUrl={setUrl}
        uuid={uuid}
        setUuid={setUuid}
        apps={apps}
        setApp={setApp}
        selectedAppName={app}
      />
      <SelectSpaceVisibility
        spaces={spaces}
        onlyShowToSpaceUuids={onlyShowToSpaceUuids}
        setOnlyShowToSpaceUuids={setOnlyShowToSpaceUuids}
      />
      <hr />
      <Preview menuItem={newMenuItem} />
      <hr />
    </Container>
  );
};

export default CreateItem;
