export interface MenuItemOption {
  value: string,
  label: string
}

export const MenuItemOptions = [
  { value: "app", label: "App"},
  { value: "resource", label: "Resource"},
  { value: "external_link", label: "Link"},
  { value: "seperator", label: "Divider"},
  { value: "admin", label: "Admin"},
  { value: "help", label: "Help"},
  { value: "home", label: "Newsfeed"}
];

