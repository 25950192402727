import React from 'react'
import Resource from './resource'
import App from './app'
import Seperator from './seperator'
import Url from './url'
import { AppType } from '../../initial_data'

interface Props {
  selectedOption: string
  humanName: string
  setHumanName: (str: string) => void,
  icon:string
  setIcon: (str: string) => void,
  url: string,
  setUrl: (str: string) => void,
  uuid: string
  setUuid: (str: string) => void,
  setApp: (str: string) => void,
  apps: AppType[],
  selectedAppName: string,
}

const Form: React.FC<Props> = ({
  selectedOption,
  humanName,
  setHumanName,
  icon,
  setIcon,
  url,
  setUrl,
  uuid,
  setUuid,
  setApp,
  apps,
  selectedAppName,
}) => {
  switch (selectedOption) {
    case "resource":
      return (
        <Resource
          humanName={humanName}
          setHumanName={setHumanName}
          icon={icon}
          setIcon={setIcon}
          uuid={uuid}
          setUuid={setUuid}
        />
      );
    case "external_link":
      return (
        <Url
          humanName={humanName}
          setHumanName={setHumanName}
          icon={icon}
          setIcon={setIcon}
          url={url}
          setUrl={setUrl}
        />
      );
    case "app":
      return (
        <App
          setApp={setApp}
          appOptions={apps.map(({id, human_name}) => ({label: human_name, value: id}))}
          selectedAppName={selectedAppName}
        />
      );
    case "separator":
      return <Seperator />;
    default:
      return null;
  }
};

export default Form
