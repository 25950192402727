import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { Icons } from "../initial_data";

const IconContainer = styled.div`
  border: 1px solid #666666;
  width: 22px;
  cursor: pointer;
  background: white;
  &:hover {
    transform: scale(3);
  }
  display: inline-block;
  margin: 1px;
`;

const modalStyle = {
  content: {
    overflow: "scroll",
    top: "50%",
    width: "600px",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    overflow: "scroll",
  },
};

interface Props {
  iconModalOpen: boolean;
  setIconModalOpen: (bool: boolean) => void;
  setIcon: (str: string) => void;
}

const IconsModal: React.FC<Props> = ({
  iconModalOpen,
  setIconModalOpen,
  setIcon,
}) => {
  return (
    <Modal
      isOpen={iconModalOpen}
      ariaHideApp={false}
      onRequestClose={() => setIconModalOpen(false)}
      style={modalStyle}
    >
      <div style={{}}>
        <div>
          {Icons.map((icon) => {
            return (
              <IconContainer>
                <i
                  className={`icon-${icon}`}
                  style={{ fontSize: 16 }}
                  onClick={() => {
                    setIcon(icon);
                    setIconModalOpen(false);
                  }}
                />
              </IconContainer>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default IconsModal;
