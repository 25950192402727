import React from "react";
import MenuItems from "./menu_items";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";
import { MenuItem } from "./menu_item";

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: calc(182px + 30px);
  flex-direction: column;
`;

interface Props {
  menuItems: Array<MenuItem>,
  editMenuItem: (obj: MenuItem) => void,
  selectedMenuItem: MenuItem | null,
  setMenuItems: (arr: MenuItem[]) => void
}

const Menu: React.FC<Props> = ({
  menuItems,
  editMenuItem,
  selectedMenuItem,
  setMenuItems
}) => {
  return (
    <Droppable droppableId={"1"}>
      {(provided) => (
        <Container ref={provided.innerRef} {...provided.droppableProps}>
          {menuItems.map((item, index) => {
            return (
              <MenuItems
                index={index}
                item={item}
                key={item.id}
                editMenuItem={editMenuItem}
                menuItems={menuItems}
                setMenuItems={setMenuItems}
                selected={!!(selectedMenuItem && (selectedMenuItem.id === item.id))}
              />
            );
          })}
          {provided.placeholder}
        </Container>
      )}
    </Droppable>
  );
};

export default Menu;
