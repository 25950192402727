import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import { deleteMenuItem } from '../common_methods'
import { MenuItem } from './menu_item'

const Container = styled.div`
  padding: 10px;
  color: #666666;
  padding: 5px 10px 5px 5px;
  font-size: 14px;
  background-color: white;
`;

const WIDTH = 182;

export interface AppConfigProps {
  id: string,
  icon?: string,
  url?: string,
  human_name?: string,
}

export interface MenuItemProps {
  item: MenuItem;
  editMenuItem: (obj: MenuItem) => void;
  selected: boolean;
  menuItems: Array<MenuItem>;
  setMenuItems: (arr: MenuItem[]) => void;
}
interface DraggableMenuItemProps extends MenuItemProps {
  index: number
}

const DraggableMenuItem: FunctionComponent<DraggableMenuItemProps> = ({
  item,
  index,
  editMenuItem,
  selected,
  menuItems,
  setMenuItems
}) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided) => (
        <Container
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={
            {
              ...provided.draggableProps.style,
              padding: 0
            }
          }
          ref={provided.innerRef}
        >
          <Item
            item={item}
            selected={selected}
            editMenuItem={editMenuItem}
            menuItems={menuItems}
            setMenuItems={setMenuItems}
          />
        </Container>
      )}
    </Draggable>
  );
};

const Item: FunctionComponent<MenuItemProps> = ({
  item,
  editMenuItem,
  selected,
  menuItems,
  setMenuItems,
}) => {
  const color = selected ? "white" : "",
    backgroundColor = selected ? "#457eda" : "";

  if (item.type === "seperator") {
    return (
      <div
        className="is-divider"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div
          style={{
            width: WIDTH,
            borderRight: "1px solid black",
            borderLeft: "1px solid black",
          }}
        >
          <hr style={{ margin: ".5rem" }} />
        </div>
        <button
          onClick={() => deleteMenuItem({ menuItems, setMenuItems, item })}
          className="delete is-small"
          style={{ backgroundColor: "#f14668", marginRight: 5 }}
        >
          x
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        color,
        backgroundColor,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div onClick={() => { editMenuItem(item); }} style={{ padding: 5, width: WIDTH, borderRight: "1px solid black", borderLeft: "1px solid black" }}>
        <i className={`icon-${item.icon}`} style={{ fontSize: 16 }} />
        {item.display_name}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: 5,
          paddingRight: 5,
        }}
      >
        <button
          onClick={() => deleteMenuItem({ menuItems, setMenuItems, item })}
          className="delete is-small"
          style={{ backgroundColor: "#f14668" }}
        >
          x
        </button>
      </div>
    </div>
  );
};

export default DraggableMenuItem
